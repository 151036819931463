<template>
  <v-container fluid>
    <v-card-title>추천 질문 등록</v-card-title>
    <v-card-subtitle class="py-0 pt-3">
      기본 학학이 아래에서 추천 질문으로 노출될 문구를 등록해주세요.
    </v-card-subtitle>
    <v-card-subtitle class="py-0">
      등록된 추천 질문은 랜덤 순서로 노출됩니다.
    </v-card-subtitle>
    <v-card-subtitle class="py-0">
      <v-divider class="my-3" style="border-color: black" />
    </v-card-subtitle>
    <v-list-item class="mb-3" outlined>
      <span style="white-space:nowrap" class="pa-2">라벨</span>
      <LabelInput v-model="stamp" />
      <span style="white-space:nowrap" class="pa-0 mr-4">
        노출여부
      </span>
      <v-btn-toggle v-model="req.isShow" mandatory color="#9146ff" dense>
        <v-btn class="pa-5" :value="true">
          노출
        </v-btn>
        <v-btn class="pa-5" :value="false">
          비노출
        </v-btn>
      </v-btn-toggle>
      <v-btn :color="req.id ? 'green' : '#ffd600'" :dark="req.id ? true : false" light depressed class="pa-5 ml-12" @click="createRecommendQuestion">
        {{ req.id ? '수정' : '추가' }}
      </v-btn>
      <v-btn v-if="req.id" dark color="red" depressed class="pa-5 ml-3" @click="cancel()">
        취소
      </v-btn>
    </v-list-item>
    <v-list-item>
      <span class="pa-2">
        질문
      </span>
      <v-text-field v-model="req.question" style="max-width: 750px" color="#9146ff" outlined hide-details dense @keyup.enter="add" />
    </v-list-item>
    <v-card-subtitle class="py-0">
      <v-divider class="my-3" style="border-color: black" />
    </v-card-subtitle>
    <v-card outlined flat>
      <v-data-table :headers="headers" :items="recommendQuestionList" :items-per-page="offset" hide-default-footer>
        <template v-slot:item.updatedAt="{ item }">
          {{ day(item.updatedAt) }}
        </template>
        <template v-slot:item.showFlag="{ item }">
          {{ item.isShow ? '노출' : '비노출' }}
        </template>
        <template v-slot:item.stamp="{ item }">
          <QuestionTag :tags="item.stamp" />
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn depressed color="green" dark class="mr-5" @click="update(item)">
            수정
          </v-btn>
          <v-btn depressed color="red" dark @click="deleteRecommendQuestion(item.id)">
            삭제
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-divider />
          <v-container fluid class="row" no-gutters>
            <v-col cols="1">
              <v-select v-model="offset" color="#ffd600" :items="[5, 10, 15, 25, 50, 100]" outlined dense hide-details />
            </v-col>
            <v-spacer />
            <v-col>
              <v-pagination v-model="pageNumber" total-visible="13" :length="page" circle color="#ffd600" @input="getListRecommendQuestion" />
            </v-col>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, watch, toRefs } from '@vue/composition-api'
import { day } from '@/helper.ts'
import QuestionTag from '@/components/QuestionTag.vue'
import LabelInput from '@/components/input/Label.vue'

export default defineComponent({
  components: {
    QuestionTag,
    LabelInput,
  },

  setup(props, { root }) {
    const state = reactive({
      headers: [
        { text: 'no', value: 'id' },
        { text: '노출상태', value: 'showFlag' },
        { text: '라벨', value: 'stamp' },
        { text: '질문', value: 'question' },
        { text: '최종 등록일시', value: 'updatedAt' },
        { text: null, value: 'action' },
      ],
      stamp: {
        id: null,
        firstDepth: null,
        secondDepth: null,
        thirdDepth: null,
      },
      recommendQuestionList: [],
      edit: null,
      pageNumber: 1,
      page: 1,
      offset: 10,
      req: {
        id: null,
        question: null,
        answer: null,
        isShow: true,
        stampId: null,
      },
    })

    const getListRecommendQuestion = async () => {
      try {
        const list = await root.$store.dispatch('recommendQuestion/getListRecommendQuestion', {
          offset: state.offset,
          pageNumber: state.pageNumber,
        })

        state.recommendQuestionList = list.data
        state.page = Math.ceil(list.totalCount / state.offset)
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const update = async (item) => {
      state.req = Object.assign({}, item)
      state.stamp = Object.assign({}, state.req.stamp[0])
    }

    const cancel = () => {
      state.req = {}
      state.stamp = {}
    }

    const updateRecommendQuestion = async () => {
      try {
        if (!confirm('수정하시겠습니까?')) return

        state.req.id = Number(state.req.id)
        state.req.stampId = Number(state.stamp.id)

        await root.$store.dispatch('recommendQuestion/updateRecommendQuestion', {
          question: state.req.question,
          answer: state.req.answer,
          isShow: state.req.isShow,
          id: Number(state.req.id),
          stampId: Number(state.req.stampId),
        })
        cancel()
        getListRecommendQuestion()
      } catch (error) {
        console.error(error)
        alert(error)
      }
    }

    const createRecommendQuestion = async () => {
      try {
        if (state.req.id) return updateRecommendQuestion()
        if (!confirm('추가하시겠습니까?')) return

        state.req.stampId = Number(state.stamp.id)

        await root.$store.dispatch('recommendQuestion/createRecommendQuestion', {
          question: state.req.question,
          answer: state.req.answer,
          isShow: state.req.isShow,
          stampId: Number(state.req.stampId),
        })
        cancel()
        getListRecommendQuestion()
      } catch (error) {
        console.error(error)
        alert(error)
      }
    }

    const deleteRecommendQuestion = async (id) => {
      if (!confirm('삭제하시겠습니까?')) return

      await root.$store
        .dispatch('recommendQuestion/deleteRecommendQuestion', {
          id: Number(id),
        })
        .then(() => {
          getListRecommendQuestion()
        })
        .catch((err) => {
          console.error(err)
          alert(err)
        })
    }

    onBeforeMount(() => {
      getListRecommendQuestion()
    })

    watch(
      () => state.offset,
      () => {
        state.pageNumber = 1
        getListRecommendQuestion()
      },
    )

    return {
      ...toRefs(state),
      update,
      cancel,
      getListRecommendQuestion,
      createRecommendQuestion,
      deleteRecommendQuestion,
      day,
    }
  },
})
</script>
